import React from 'react'
import { Link, graphql } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-amplitude-analytics'

import remark from 'remark'
import recommended from 'remark-preset-lint-recommended'
import remarkHtml from 'remark-html'

import PageLayout from '../layout/page'
import MetaSEO from '../components/MetaSEO'
import Home from '../layout/HomePageSpanish'

import '../styles/index.scss'

class HomePage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
    }
  }

  componentDidMount() {
    this.setState({ loaded: true })
  }
  componentWillUnmount() {}

  applyMarkdown(text) {
    const content = remark()
      .use(recommended)
      .use(remarkHtml)
      .processSync(text)
      .toString()

    return content
  }

  handleFormLoaded() {
    setTimeout(
      () => document.querySelector('#form-area').classList.add('loaded'),
      500
    )
  }

  render() {
    return (
      <>
        <MetaSEO
          title="Tiffany Cabán | Demócrata para el Consejo Municipal, Distrito 22"
          keywords={[
            `tiffany cabán`,
            `tiffany caban`,
            `caban for queens`,
            `caban`,
            `cabán`,
            `queens`,
            `council district 22`,
          ]}
        />
        <Home
          frontmatter={this.props.data.home.frontmatter}
          handleFormLoaded={this.handleFormLoaded.bind(this)}
          loaded={this.state.loaded}
          applyMarkdown={this.applyMarkdown.bind(this)}
          endorsements={this.props.data.endorsements.frontmatter}
          // news={this.props.data.news.edges}
          press={this.props.data.press.frontmatter}
        />
      </>
    )
  }
}

// <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
//   <Image />
// </div>
export default HomePage

export const HomePageQuery2 = graphql`
  {
    endorsements: markdownRemark(
      frontmatter: { uniq: { eq: "endorsements" } }
    ) {
      frontmatter {
        individualEndorsements {
          name
          link
          image
          position
          is_featured
          body
        }
        orgList {
          link
          image
          name
        }
      }
    }

    home: markdownRemark(frontmatter: { uniq: { eq: "home" } }) {
      id
      html
      frontmatter {
        slogan
        introSection {
          title
          body
          cta
        }
        issuesSection {
          title
          body
          cta
        }
      }
    }

    press: markdownRemark(frontmatter: { uniq: { eq: "press" } }) {
      id
      html
      frontmatter {
        coverage {
          title
          source
          blurb
          publishedDate
          image
          link
          is_featured
        }
      }
    }
  }
`

// news: allMarkdownRemark(
//   filter: {
//     collection: { eq: "news" }
//   },
//   sort: { order: DESC, fields: [frontmatter___publishingDate]},
//   limit: 4
// ) {
//     totalCount
//     edges {
//       node {
//         id
//         html
//         fields {
//           slug
//         }
//         frontmatter{
//           title
//           image
//           publishingDate
//           blurb
//         }
//       }
//     }
// }
