import React from 'react'
import Icon from 'react-fontawesome'
import { Container, Row, Col } from 'react-bootstrap'

import TransparentHeader from '../components/TransparentHeaderSpanish'
import FullPageSection from '../components/FullPageSection'
import HeroTitle from '../components/HeroTitle'
import VolunteerFormSpanish from '../components/VolunteerFormSpanish'
import PaidForBy from '../components/PaidForByCabanForCouncil'
import Footer from '../components/Footer'
import bgImage from '../img/home.jpg'
import tlcLogo from '../img/logo-white.svg'

const HomePage = (props) => (
  <main className="">
    <FullPageSection
      className="overlay-blue p-4 splash"
      bgImage={bgImage}
      bgPosition={'top'}
    >
      <TransparentHeader noLogo />
      <div className="mb-5">
        <img src={tlcLogo} className="tlc-logo" />
      </div>
      <HeroTitle
        textArray={[
          'Únete a nuestra Campaña',
          'para representar al Distrito 22',
          'en el Consejo Municipal de NYC',
          'Vota • Martes 22 de junio',
        ]}
      />
      <VolunteerFormSpanish className="mt-5 mb-5" />

      <section className="mt-4">
        <h3>Conversa con nosotros</h3>
        <div className="d-flex">
          <a
            href="https://www.facebook.com/cabanforqueens"
            className="d-block mr-3 color-white"
            target="_blank"
          >
            <Icon size="2x" name="facebook" />
          </a>
          <a
            href="https://instagram.com/cabanforqueens/"
            className="d-block mr-3 color-white"
            target="_blank"
          >
            <Icon size="2x" name="instagram" />
          </a>
          <a
            href="https://twitter.com/tiffany_caban"
            className="d-block mr-3 color-white"
            target="_blank"
          >
            <Icon size="2x" name="twitter" />
          </a>
        </div>
      </section>
    </FullPageSection>
    <Footer mini />
  </main>
)

export default HomePage
