import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/logo-primary.svg'

import {
  Navbar,
  Nav,
  Button,
  ButtonGroup,
  NavDropdown,
  Container,
} from 'react-bootstrap'

const TransparentHeader = (props) => (
  <div className="position-absolute top right w-100 z-toppest">
    <Navbar
      expand="xl"
      className={` p-0 ${props.className} justify-content-end`}
    >
      {!props.noLogo && (
        <Navbar.Brand href="/es">
          <img src={logo} />
        </Navbar.Brand>
      )}
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        style={{ backgroundColor: 'rgba(255,255,255,0.5)' }}
      />
      <Navbar.Collapse
        id="responsive-navbar-nav"
        className="justify-content-end align-items-end"
      >
        <Nav>
          <div className="text-right d-lg-flex">
            <Nav.Link
              href="/es/about"
              className="color-white font-sans-serif font-weight-bold mr-lg-3"
            >
              Tiffany
            </Nav.Link>
            <Nav.Link
              href="/es/issues"
              className="color-white font-sans-serif font-weight-bold mr-lg-3"
            >
              Prioridades
            </Nav.Link>
            {/* <Nav.Link href="/public-safety" className='color-white font-sans-serif font-weight-bold mr-lg-3'>Public Safety</Nav.Link> */}
            {/* <Nav.Link href="/greennewdeal" className='color-white font-sans-serif font-weight-bold mr-lg-3'>Green New Deal</Nav.Link> */}
            <div style={{ marginRight: '8px' }}>
              <Nav.Link
                href="https://www.nycvotes.org/campaigns/tiffanycaban/contributions/new?ref=website"
                className="d-inline-block font-sans-serif font-weight-bold rounded px-3 py-2 color-white bg-base2"
              >
                Donar
              </Nav.Link>
            </div>
            <ButtonGroup aria-label="Basic example">
              <Button size="sm" variant="light">
                <a href="/">English</a>
              </Button>
              <Button size="sm" variant="light" active>
                <a href="/es/">Español</a>
              </Button>
            </ButtonGroup>
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </div>
)

export default TransparentHeader
